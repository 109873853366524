import context from "./purchaseOrderContext";
import React, {useContext, useEffect, useReducer, useRef} from "react";
import reducer from "./purchaseOrderReducer";
import types from "./purchaseOrderTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";
import sortContext from "../sort/sortContext";


const initialState = {
	loading: false,
	purchaseOrders: [],
	pageInfo: {
		limit: 999999,
		offset: 0,
		maxOffset: 0,
		hasNextPage: false,
		hasPreviousPage: false,
	},
	filters: {}
};


export default ({children}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);
	const {asc, desc, sortBy} = useContext(sortContext);
	const abortController = useRef(null);

	useEffect(() => {
		dispatch({
			type: types.SET_PAGE_INFO,
			payload: {
				...state.pageInfo,
				offset: 0
			}
		});
	}, [state.pageInfo.limit]);

	useEffect(() => {
		abortController.current = new AbortController();

		(async () => {
			try {
				await getPurchaseOrders();
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.current.abort();
		}
	}, [state.pageInfo.offset, state.pageInfo.limit, state.filters.consumed, asc, desc, sortBy]);

	async function getPurchaseOrders() {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			signal: abortController.current.signal,
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getPurchaseOrders ($pagination:PaginationInput, $filters:PurchaseOrderFilters, $sort:SortInput){
					  getPurchaseOrders (pagination:$pagination, filters:$filters, sort:$sort) {
						purchaseOrders {
						id
						number
						description
						totalValue
                        currencyId
						currency { name }
						pdfFileName
						valueConsumed
						valueRemaining
						customer {
							id
							fullName
						}
						comments
					  }
						pageInfo {
						  hasNextPage
						  hasPreviousPage
						  offset
						  limit
						  maxOffset
						}
					  }
					}
						`,
				variables: {
					pagination: {
						limit: state.pageInfo.limit,
						offset: state.pageInfo.offset
					},
					filters: {
						...state.filters
					},
					sort: {
						column: sortBy,
						asc,
						desc
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_PURCHASE_ORDERS,
					payload: json.data.getPurchaseOrders.purchaseOrders
				});
				dispatch({
					type: types.SET_PAGE_INFO,
					payload: json.data.getPurchaseOrders.pageInfo
				})
			}
		}
	}

	async function createPurchaseOrder(purchaseOrder) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation createPurchaseOrder ($purchaseOrder:CreatePurchaseOrderInput!) {
					  createPurchaseOrder(purchaseOrder: $purchaseOrder) {
						id
						number
						description
						totalValue
						currencyId
						pdfContent
						pdfFileName
						customerId
						comments
					  }
					}
				`,
				variables: {
					purchaseOrder: {
						...purchaseOrder,
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				abortController.current.abort();
				abortController.current = new AbortController();
				await getPurchaseOrders();
			}
		}
	}

	async function editPurchaseOrder(purchaseOrderId, purchaseOrder) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation editPurchaseOrder ($purchaseOrderId: ID!, $purchaseOrder:EditPurchaseOrderInput!) {
						editPurchaseOrder (purchaseOrderId:$purchaseOrderId, purchaseOrder:$purchaseOrder) {
							id
							number
							description
							totalValue
							currencyId
							pdfFileName
							pdfContent
							customerId
							comments
							customer {
								id
								fullName
							}
						}
					}
				`,
				variables: {
					purchaseOrderId,
					purchaseOrder: {
						number: purchaseOrder.number,
						description: purchaseOrder.description,
						totalValue: purchaseOrder.totalValue,
						currencyId: purchaseOrder.currencyId,
						pdfFileName: purchaseOrder.pdfFileName,
						pdfContent: purchaseOrder.pdfContent,
						customerId: purchaseOrder.customerId,
						comments: purchaseOrder.comments,
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				// refreshing list of POs because valueConsumed and valueRemaining need to be recalculated
				await getPurchaseOrders();
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getPurchaseOrders,
			createPurchaseOrder,
			editPurchaseOrder,
			dispatch
		}}>
			{children}
		</context.Provider>
	);
}
