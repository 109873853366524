import EditPurchaseOrder from "./EditPurchaseOrder";

function PurchaseOrderRow({purchaseOrder}) {
	return (
		<tr>
			<td>{purchaseOrder.number}</td>
			<td>{purchaseOrder.description}</td>
			<td>{purchaseOrder.totalValue}</td>
			<td>{purchaseOrder.currency.name}</td>
			<td><a href={`${process.env.REACT_APP_endPoint}/purchaseOrders/downloadPDF/${purchaseOrder.id}`}>
				{purchaseOrder.pdfFileName}
			</a></td>
			<td>{purchaseOrder.valueConsumed}</td>
			<td>{purchaseOrder.valueRemaining}</td>
			<td>{purchaseOrder.customer.fullName}</td>
			<td>{purchaseOrder.comments}</td>

			<td>
				<div className={"d-flex gap-2"}>
					<EditPurchaseOrder purchaseOrder={purchaseOrder}/>
				</div>
			</td>
		</tr>
	);
}

export default PurchaseOrderRow;
